export const initTOC = () => {
    // move table of content
    const tocNode = document.querySelector('#ez-toc-container')
    const tocContainer = document.querySelector('#toc-container')

    if (tocNode && tocContainer) {
        // @ts-ignore
        tocNode.parentNode.removeChild(tocNode)
        tocContainer.appendChild(tocNode)
    }

    // remove host and query from url to avoid redirecting on /guide
    const links = document.querySelectorAll('a.ez-toc-link')
    links.forEach((link) => {
        let href = link.getAttribute('href')
        // @ts-ignore
        href = `#${href.split('#')[1]}`
        link.setAttribute('href', href)
    })
}

export const setupTOCReadFlow = () => {
    const sections = document.querySelectorAll<HTMLElement>('.ez-toc-section[id]');
    const headerHeight = 85;
    const readingOffset = window.innerHeight * 0.15;

    const handleTOCActiveStateAndReadPercentage = () => {
        sections.forEach((section) => {
            const id = section.getAttribute('id');
            if (!id) return;

            const linkWrapper = document.querySelector<HTMLLIElement>(`nav li a[href*="#${id}"]`)?.parentElement;
            if (!linkWrapper) return;

            const containerStart = document.querySelector<HTMLElement>('#rm-content-start')?.offsetTop || 0;
            // @ts-ignore
            const sectionIndex = [...sections].indexOf(section);
            const sectionStart = section.offsetTop + containerStart;
            const nextSection = sections[sectionIndex + 1] as HTMLElement;
            let nextSectionStart = nextSection ? nextSection.offsetTop + containerStart : null;

            if (!nextSection) {
                const articleContainer = document.querySelector<HTMLElement>('#rm-article-container');
                if (articleContainer) {
                    nextSectionStart = containerStart + articleContainer.offsetTop + articleContainer.clientHeight - headerHeight;
                }
            }


            if (nextSectionStart === null) return;

            const sectionHeight = nextSectionStart - sectionStart;
            const scrollPosition = window.scrollY + headerHeight + readingOffset;

            if (scrollPosition > sectionStart && scrollPosition < nextSectionStart) {
                if (!linkWrapper.classList.contains('active')) {
                    linkWrapper.classList.add('active');
                }
                const percentRead = ((scrollPosition - sectionStart) / sectionHeight) * 100;
                linkWrapper.style.setProperty('--wp-toc-active-read-percentage', `${percentRead}%`);
            } else {
                linkWrapper.style.setProperty('--wp-toc-active-read-percentage', '0%');
                if (linkWrapper.classList.contains('active')) {
                    linkWrapper.classList.add('read');
                    linkWrapper.classList.remove('active');
                }
            }
        });
    };

    const handleEntryAutoScrollWithContent = (entry: IntersectionObserverEntry) => {
        const id = entry.target.getAttribute('id');
        if (!id) return;

        const tocWrapper = document.querySelector<HTMLElement>('.toc-content');
        if (!tocWrapper) return;

        const selector = `nav li a[href*="#${id}"]`;
        const element = document.querySelector<HTMLElement>(selector);
        if (!element) return;

        const currentSectionLink = element.parentElement;
        if (!currentSectionLink) return;

        const startOfVisibleView = tocWrapper.scrollTop;
        const endOfVisibleView = tocWrapper.scrollTop + tocWrapper.clientHeight;

        // @ts-ignore
        const sectionIndex = [...sections].indexOf(entry.target as HTMLElement);
        const previousSection = sections[sectionIndex - 1] as HTMLElement;
        const previousSectionId = previousSection ? previousSection.getAttribute('id') : id;
        const previousSectionLink = document.querySelector<HTMLElement>(`nav li a[href*="#${previousSectionId}"]`)?.parentElement ?? currentSectionLink;

        if ((currentSectionLink.offsetTop + currentSectionLink.clientHeight) > endOfVisibleView) {
            tocWrapper.scrollTop = previousSectionLink.offsetTop;
        }

        if (previousSectionLink.offsetTop < startOfVisibleView) {
            tocWrapper.scrollTop = previousSectionLink.offsetTop;
        }
    };

    window.addEventListener('scroll', handleTOCActiveStateAndReadPercentage, {passive: true});

    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.intersectionRatio > 0) {
                handleEntryAutoScrollWithContent(entry);
            }
        });
    });

    sections.forEach((section) => {
        observer.observe(section);
    });

    return () => {
        window.removeEventListener('scroll', handleTOCActiveStateAndReadPercentage);
        observer.disconnect();
    };
};
