import React, {useEffect, useState} from "react"

interface Props {
    sharedMedia: string
    shareNavigator?: IShareNavigator
    hasShareButton?: boolean
}

interface IShareNavigator {
    title: string
    text: string
    url: string
}

const ShareButton: React.FC<IShareNavigator> = (props) => {
    const [isSupported, setIsSupported] = useState(false);

    useEffect(() => {
        if (navigator.canShare && navigator.canShare(props)) {
            setIsSupported(true);
        }
    }, [props]);

    const handleShare = async () => {
        await navigator.share(props);
    };

    return (
        <>
            {isSupported && (
                <button onClick={handleShare}>
                    <i className="fa-solid fa-link" />
                </button>
            )}
        </>
    )
}

interface MediaShareLinksProps {
    sharedMedia: string
    shareNavigator?: IShareNavigator
    hasShareButton?: boolean
    urlToShare: string
}

export default function MediaShareLinks({
    sharedMedia,
    shareNavigator,
    hasShareButton,
    urlToShare
}: MediaShareLinksProps) {
    const medias: Record<string, { logo: string; link: string }> = {
        facebook: {
            logo: 'fa-brands fa-facebook',
            link: `https://www.facebook.com/sharer/sharer.php?u=${urlToShare}`,
        },
        x: {
            logo: 'fa-brands fa-x-twitter',
            link: `https://twitter.com/share?url=${urlToShare}`,
        },
        linkedin: {
            logo: 'fa-brands fa-linkedin-in',
            link: `https://www.linkedin.com/sharing/share-offsite/?url=${urlToShare}`,
        },
        whatsapp: {
            logo: 'fa-brands fa-whatsapp',
            link: `https://api.whatsapp.com/send?text=${urlToShare}`,
        },
    }

    const mediaList = sharedMedia.split(',').map(media => media.trim().toLowerCase())

    return (
        <div className={'flex gap-3 text-blue-700 text-xl'}>
            {mediaList.map((media, index) => {
                const found = medias[media];
                if (found) {
                    return (
                        <a key={index} href={found.link} target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}}>
                            <i className={found.logo} />
                        </a>
                    );
                }
            })}

            {hasShareButton && shareNavigator && <ShareButton {...shareNavigator} />}
        </div>
    )
}
