'use client'
import React, {useEffect, useRef, useState} from "react";
import {faqSection, initResponsiveTables} from "@/app/utils/wp-utils";
import {setupSidebarIntendedItemsOnScroll} from "@/app/utils/advertizing-block-utils";
import PageSidebar from "@/app/components/auctor/page/PageSidebar";
import {setupTOCReadFlow, initTOC} from "@/app/utils/toc";
import {format} from "date-fns";
import PageAuthor from "@/app/components/auctor/page/PageAuthor";

export default function WPPageContent(
    {wpContentHTML, showInnerTitle, title, hasSidebar, sidebarRight, modified, readingTimeMinutes, commentNumber, displayTopBotInfos}: {
        wpContentHTML: any
        showInnerTitle: boolean
        readingTimeMinutes: number
        modified: string
        displayTopBotInfos: boolean
        title?: string
        hasSidebar: boolean
        sidebarRight?: boolean
        commentNumber?: number
    }) {
    const isInit = useRef(false)

    useEffect(() => {
        if (!isInit.current) {
            initWPPageScripts(hasSidebar)
            isInit.current = true;
        }
    }, []);

    const intersectionAnchorRef = React.createRef<HTMLDivElement>()

    return (
        <div
            ref={intersectionAnchorRef}
            className={`flex flex-col lg:flex-row pt-5 lg:pt-10 lg:gap-16 mx-4 w-full lg:max-w-screen-lg+
                ${hasSidebar ? ' justify-center md:justify-between' : ' justify-center'}`}
        >

            {hasSidebar && !sidebarRight &&
                <PageSidebar
                    hasTOC
                    intersectionAnchorRef={intersectionAnchorRef}
                />
            }

            <div className={`bg-white w-full${hasSidebar ? ' lg:max-w-[900px]' : ''}`}>
                {displayTopBotInfos &&
                    <div className={'border-b py-2 mb-6 text-sm flex justify-between items-center'}>
                        <div className={'hidden lg:flex text-blue-600'}>
                            Mis à jour le {format(modified, 'dd.MM.yyyy')}. Temps de lecture : {readingTimeMinutes} mn
                        </div>

                        <div className={'lg:hidden text-blue-600'}>
                            Mis à jour le {format(modified, 'dd.MM.yy')}. Temps de lecture : {readingTimeMinutes} mn
                        </div>

                        <MediaLinks commentNumber={commentNumber} />
                    </div>
                }

                {showInnerTitle && title &&
                    <div className={"rm-page-content"}>
                        <h2 dangerouslySetInnerHTML={{'__html': title}} />
                    </div>
                }

                <div
                    className={'post-content'}
                    dangerouslySetInnerHTML={{__html: wpContentHTML ?? ''}}
                />

                {displayTopBotInfos &&
                    <div className={'w-full flex'}>
                        <PageAuthor
                            redacted
                            textColor={'blue-700'}
                            picSize={45}
                            authorName="Delphine Bardou"
                            authorPageLink="/guide/communication/delphine-bardou"
                            profilePicSrc={`https://reassurez-moi.fr/guide/wp-content/themes/wp-rm-theme/static/img/delphine-bardou.png`}
                        />
                    </div>
                }
            </div>

            {hasSidebar && sidebarRight &&
                <PageSidebar
                    hasTOC
                    intersectionAnchorRef={intersectionAnchorRef}
                />
            }
        </div>
    )
}

export function initWPPageScripts (hasSidebar : boolean) {
    setupTOCReadFlow();
    faqSection();
    if (hasSidebar) {
        setupSidebarIntendedItemsOnScroll();
        initTOC();
    }
    const isDeviceLowerThanMD = window.matchMedia('(max-width: 992px)').matches;
    if (isDeviceLowerThanMD) {
        initResponsiveTables();
    }
}

export function MediaLinks({commentNumber}: { commentNumber: number | undefined }) {
    const [currentUrl, setCurrentUrl] = useState<string>('');
    useEffect(() => {
        setCurrentUrl(window?.location.href);
    }, []);
    const xUrl = `https://twitter.com/share?url=${currentUrl}`
    const linkedinUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${currentUrl}`
    const copyToClipboard = () => {
        navigator.clipboard.writeText(currentUrl).then(() => {
            alert('Lien copié dans le presse-papiers !');
        }).catch((err) => {
            console.error('Erreur lors de la copie du lien : ', err);
        });
    }

    return <>
        <div className={'flex justify-center items-center lg:gap-4'}>
            {commentNumber !== 0 &&
                <div className={'text-gray-300 hover:cursor-pointer hover:text-blue-700 transition duration-150 flex justify-center items-center'}>
                    <a
                        href={'#comments'}
                        className={'p-2'}
                    >
                        <i className="fa-light fa-comment pr-1 text-lg" />

                        {commentNumber}
                    </a>
                </div>
            }

            <div className="gap-2 lg:hidden group relative">

                <i className="fa-solid fa-share-nodes px-1.5 text-gray-300 hover:text-blue-700 text-lg transition duration-150 p-2" />

                <div className="hidden group-hover:flex bg-white shadow-soft border border-gray-200 absolute top-10 right-0.5 -top-10 rounded-md p-2">
                    <div className="flex flex-col gap-2 items-center text-gray-900 text-lg">
                        <a
                            href={xUrl}
                            target="_blank"
                            className={'p-2'}
                        >
                            <i className="fa-brands fa-x-twitter" />
                        </a>

                        <a
                            href={linkedinUrl}
                            target="_blank"
                            className={'p-2'}
                        >
                            <i className="fa-brands fa-linkedin-in" />
                        </a>

                        <div
                            onClick={copyToClipboard}
                            className={'p-2'}
                        >
                            <i className="fa-solid fa-link" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="hidden lg:flex gap-2 transition">
                <div
                    className={'text-gray-300 hover:cursor-pointer hover:text-blue-700 transition duration-150 py-1 px-2'}
                    onClick={copyToClipboard}
                >
                    <i className="fa-solid fa-link" />
                </div>

                <a
                    href={xUrl}
                    target="_blank"
                    className="text-gray-300 hover:text-blue-700 transition duration-150 py-1 px-2"
                >
                    <i className="fa-brands fa-x-twitter" />
                </a>

                <a
                    href={linkedinUrl}
                    target="_blank"
                    className="text-gray-300 hover:text-blue-700 transition duration-150 py-1 px-2"
                >
                    <i className="fa-brands fa-linkedin-in" />
                </a>
            </div>
        </div>
    </>
}
