'use client'
import {format} from "date-fns";
import React, {useEffect} from "react";
import {initResponsiveTables} from "@/app/utils/wp-utils";
import {initTOC} from "@/app/utils/toc";
import {setupSidebarIntendedItemsOnScroll} from "@/app/utils/advertizing-block-utils";
import Image from "next/image";
import PageSidebar from "@/app/components/auctor/page/PageSidebar";
import Breadcrumb from "@/app/components/atomic/molecules/Breadcrumb";
import {TBreadcrumb, IWPContentPayload} from "@/app/models/wp";

export default function WPPostContent({postContent, breadcrumb}: {
    postContent: IWPContentPayload
    breadcrumb?: TBreadcrumb
}) {
    useEffect(() => {
        initTOC();
        setupSidebarIntendedItemsOnScroll();
        // only init responsive tables on md and lower devices
        const isDeviceLowerThanMD = window.matchMedia('(max-width: 992px)').matches
        if (isDeviceLowerThanMD) {
            // TODO: try to render those tables server side
            initResponsiveTables();
        }
    }, []);


    const intersectionAnchorRef = React.createRef<HTMLDivElement>()

    const shareData = {
        title: postContent.title,
        text: '',
        url: '',
    }

    return (
        <div className={'flex justify-between relative px-5 lg:px-0 mx-auto w-full lg:max-w-screen-lg+ lg:gap-16'}>
            <div className={'lg:max-w-[900px]'}>
                {breadcrumb &&
                    <Breadcrumb
                        breadcrumb={breadcrumb}
                    />
                }

                <h1 className={'text-blue-700 font-semibold text-3xl my-8'}>
                    {postContent.title}
                </h1>

                <div className={'rounded-2xl overflow-hidden'}>
                    {typeof postContent.featuredImage === "string" &&
                        <Image
                            className='relative'
                            alt={postContent.title}
                            src={postContent.featuredImage}
                            fill
                        />
                    }
                </div>

                {postContent.createdAt && (
                    <div className={'my-5'}>
                        Publié le {format(postContent.createdAt, 'dd/MM/yyyy')} par Delphine Bardou
                    </div>
                )}

                <div
                    className={'text-blue-600'}
                >
                    <i className="pr-2 fa-regular fa-clock" />
                    {postContent.readingTimeMinutes} min
                </div>

                <div
                    ref={intersectionAnchorRef}
                    className={'w-full bg-white post-content'}
                    dangerouslySetInnerHTML={{__html: postContent.content ?? ''}}
                />
            </div>

            <PageSidebar
                hasAboutBlock
                shareNavigator={shareData}
                intersectionAnchorRef={intersectionAnchorRef}
                showSidebarAdvertblockContainer={false}
            />
        </div>
    )
}
