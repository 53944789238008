'use client'
import React, {useLayoutEffect, useState} from "react"

export default function useIntersectionObserver(
    containerRef: React.RefObject<any>,
    options: IntersectionObserverInit = {},
    callback: Function | null = null,
) {
    const [isIntersecting, setIsIntersecting] = useState(true)

    useLayoutEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            setIsIntersecting(entry.isIntersecting)
            callback?.(entry.isIntersecting)
        }, options)

        const currentContainerRef = containerRef.current

        // prevent issue when html isn't load
        if (!currentContainerRef) {
            return
        }

        observer.observe(currentContainerRef)

        return () => {
            observer.unobserve(currentContainerRef)
        }
    })
    // Maybe absolute for flex wrapper

    return {
        isIntersecting,
        IntersectionAnchor: () => <div
            ref={containerRef}
            className="h-0"
        />,
    }
}
